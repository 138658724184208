<template>
    <el-dialog
        id="uploadBrochureModal"
        :visible="showModal"
        @close="closeModal"
        width="40%"
        custom-class="rounded-modal"
    >
        <template #title>
            <h3 class="dialog-header">Upload PDF Files</h3>
        </template>

        <div class="row">
            <div class="added-files col-xs-12 col-lg-6" v-if="addedFiles.length">
                <div class="added-file-names-header">
                    <div>Added files:</div>
                </div>
                <div class="added-file-name" v-for="(file, index) in addedFiles">
                    <input v-model="fileNames[index].name">
                    <i v-if="(isInitial || uploadRejected(file.name))" class="el-icon-close text-danger" title="remove file" @click="removeFile(index)"></i>
                    <i v-if="isComplete && ! uploadRejected(file.name)" class="el-icon-check text-primary " title="upload successful"></i>
                </div>
            </div>
            <div class="col-xs-12 col-lg-6" v-if="rejectedFiles.length">
                <div class="row">
                    <div class="col-xs-12 rejected-file-names-header">Rejected/Failed uploads</div>
                </div>
                <ul class="rejected-file-names">
                    <li class="text-danger" v-for="fileName in rejectedFiles">{{ fileName }}</li>
                </ul>
            </div>
        </div>
        <div v-if="uploadError" class="text-danger upload-failed">Unable to upload any files. Please contact the dev team.</div>

        <form enctype="multipart/form-data" novalidate id="upload_form">
            <div class="dropbox" @drop.prevent="addFiles" @dragenter.prevent="nowDragging" @dragover.prevent>
                <input type="file" accept="application/pdf" multiple name="files[]" :disabled="isSaving"
                    @change="filesChange($event.target.name, $event.target.files);"
                    class="input-file"
                >
                <div class="dropbox-banner" v-if="isInitial || isComplete">
                    <div class="font-weight-bold">Drag & Drop or Browse to Upload Files</div>
                    <div class="info">(Supports PDF files only)</div>
                </div>
                <p v-if="isSaving">
                    Uploading {{ fileCount }} files...
                </p>
            </div>
        </form>
        <div class="actions">
            <button class="btn btn-primary btn-md"  @click="uploadFiles" :disabled="! addedFiles.length || isComplete">Save</button>
        </div>
    </el-dialog>
</template>

<script>
import {
    Dialog,
    Upload,
    Message
} from "element-ui";
import { HTTP, VenueService } from "@/services";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_COMPLETE = 4;

export default {
    components: {
        Dialog,
        [Upload.name]: Upload,
        Message
    },
    props: {
        showModal: {
            type: Boolean,
            default:false
        },
        venue: {
            type: Object,
            require: true
        }
    },
    mounted() {
        this.currentStatus = STATUS_INITIAL;
    },
    data() {
        return {
            showBrochureUploadModal: false,
            addedFiles: [],
            rejectedFiles: [],
            fileNames: [],
            uploadError: null,
            currentStatus: null,
      }
    },
    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isComplete() {
            return this.currentStatus == STATUS_COMPLETE;
        },
        fileCount() {
            return this.addedFiles.length;
        },
        uploadRejected() {
            return (fullFileName) => {
                const name = this.extractFileNameOnly(fullFileName)
                return this.rejectedFiles.includes(name);
            }
        },
        successfulUploads() {
            if (! this.rejectedFiles.length) {
                return this.fileNames;
            }

            return this.fileNames.filter(fName => ! this.rejectedFiles.includes(fName));
        }
    },
    methods: {
        extractFileNameOnly(fullFileName) {
            return fullFileName.substring(0, fullFileName.lastIndexOf("."));
        },
        clearForm() {
            this.addedFiles = [];
            this.fileNames = [];
            this.rejectedFiles = [];
            this.uploadError = null;
        },
        resetFileObjects() {
            this.addedFiles = [];
            this.fileNames = [];
        },
        setStatus(status) {
            this.currentStatus = status;
        },
        nowDragging(event) {
        },
        closeModal() {
            this.$emit('update:showModal', false);
        },
        getBrochures() {
            HTTP.get(
                `v1/venues/${this.venue.id}/brochures?include_public_url=true`
            ).then(response => {
            }).catch(error => {
                console.log(error);
            })
        },
        uploadFiles() {
            this.currentStatus = STATUS_SAVING;
            const formData = new FormData();
            this.addedFiles.forEach((f,x) => {
                formData.append(`brochures[${x}]`, f, this.fileNames[x]?.name);
                formData.append(`extensions[${x}]`, this.fileNames[x]?.extension)
            });

            formData.append('files_count', this.addedFiles.length);
            HTTP.post(
                `v1/venues/${this.venue.id}/upload-brochure`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then (async response => {
                console.log(response);
                const uploadedFilesCount = response.data.uploaded_files?.length;
                this.$toast.success(`${uploadedFilesCount ?? 0} File(s) uploaded successfully!`);

                this.$emit('brochure-uploaded', this.venue.id);

                if (response.data.rejected_files?.length) {
                    this.rejectedFiles = response.data.rejected_files;
                } else {
                    this.closeModal();   
                }
            }).catch(error => {
                console.log(error);
                this.uploadError = true;
                this.$toast.error("Unable to upload files. Please contact the dev team.");
                this.closeModal();   

            }).finally(() => {
                this.setStatus(STATUS_COMPLETE);
            });
        },
        filesChange(fieldName, fileList) {
            // handle file changes
            if (this.isComplete) {
                this.setStatus(STATUS_INITIAL)
                this.resetFileObjects();
            }

            if (!fileList.length) return;

            this.$nextTick(() => {
                ([...fileList]).forEach(f => {
                    if (f?.type == 'application/pdf') {
                        this.addedFiles.push(f);
                        this.fileNames.push({
                            name: f.name.substring(0, f.name.lastIndexOf(".")),
                            extension: f.name.slice(f.name?.lastIndexOf(".") + 1),
                        });
                    }
                })
            })
        },
        addFiles(ev) {
            ev.preventDefault();
            if (this.isComplete) {
                this.setStatus(STATUS_INITIAL)
                this.resetFileObjects();
            }

            this.$nextTick(() => {
                let droppedFiles = ev.dataTransfer.files;
                if(! droppedFiles) {
                    return;
                }
    
                ([...droppedFiles]).forEach(f => {
                    if (f?.type == 'application/pdf') {
                        this.addedFiles.push(f);
                        this.fileNames.push({
                            name: f.name.substring(0, f.name.lastIndexOf(".")),
                            extension: f.name.slice(f.name?.lastIndexOf(".") + 1)
                        });
                    }
                });
            });

        },
        removeFile(index) {
            this.addedFiles.splice(index, 1);
            this.fileNames.splice(index, 1);
        },
        dragOverHandler(ev) {
            console.log("File(s) in drop zone");

            // Prevent default behavior (Prevent file from being opened)
            ev.preventDefault();
        },
    },
    watch: {
        addedFiles: function(value) {
            this.fileNames = [];
            value.forEach(file => {
                const name = this.extractFileNameOnly(file.name);
                const exists = this.fileNames.findIndex(item => item.name == name);
                if (exists == -1) {
                    this.fileNames.push({
                        name,
                        extension: file.name.slice(file.name?.lastIndexOf(".") + 1)
                    })
                }
            });
        },
        showModal: function(newVal) {
            this.clearForm();
        }
    }
}
</script>

<style lang="scss">
    .dialog-header {
        color: black;
    }
    #uploadBrochureModal {
        #upload_form {
            margin-top: 20px;
            height: 100%;
        }
        > .rounded-modal {
            border-radius: 7px;;
        }

        .actions {
            display: flex;
            justify-content:flex-end;
            padding-right: 10px;
            margin-top: 20px;
        }

        .dropbox {
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            outline: 2px dashed grey; /* the dash box */
            outline-offset: -10px;
            padding: 10px 10px;
            min-height: 300px; /* minimum height */
            position: relative;
            color: black;
            cursor: pointer;

            .dropbox-banner {
                text-align: center;
                .info {
                    color: dimgray;
                }
            }
        }
        
        .input-file {
            opacity: 0; /* invisible but it's there! */
            width: 100%;
            height: 200px;
            position: absolute;
            cursor: pointer;
        }
        
        .dropbox:hover {
            background: lightblue; /* when mouse over to the drop zone, change color */
        }
        
        .added-files {
            margin-bottom: 5px;
            width: 50%;
        }
    
        .added-file-names-header, .rejected-file-names-header {
            font-size: 18px;
            font-weight: 600;
            color: black;
            margin-bottom: 16px;

            &.rejected-file-names-header {
                margin-left: 20px;
            }
        }
    
        ul.rejected-file-names {
            padding-left: 30px;
        }

        .added-file-name {
            display: flex;
            align-items: center;
            > input {
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                color: black;
                padding: 8px;
                margin-bottom: 8px;
                border-radius: 6px;
                margin-right: 10px;
    
                & + i {
                    font-weight: 600;

                    &.text-danger {
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>